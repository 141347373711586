.topBox[data-v-08033a86] {
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  padding: 14px 24px 0;
}
.leftTopBox[data-v-08033a86] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.leftTopTitle[data-v-08033a86] {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}
.wanderImg[data-v-08033a86] {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: block;
  margin-right: 8px;
}
.rightTopBox[data-v-08033a86] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}
.rightTopBox .el-button[data-v-08033a86] {
  height: 32px;
}
.middleBox[data-v-08033a86] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 12px 12px 12px;
  height: calc(100vh - 92px);
}
.middleBorder[data-v-08033a86] {
  width: 80%;
  height: calc(100% - 16px);
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  background-color: #FFFFFF;
  padding: 8px;
}
.formBox[data-v-08033a86] {
  width: calc(20% - 26px);
  position: relative;
  height: 100%;
  padding: 12px 7px 0 12px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  background-color: #FFFFFF;
}
.header[data-v-08033a86] {
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 10px 0px 30px;
  color: #ffffff;
}
.header .icon[data-v-08033a86] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .icon i[data-v-08033a86] {
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 12px;
  background: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
}
[data-v-08033a86] .el-tabs__header {
  margin: 0;
}
[data-v-08033a86] .el-tabs__content {
  display: none;
}
[data-v-08033a86] .el-tabs__nav-wrap::after {
  display: none;
}
[data-v-08033a86] .el-tabs__item {
  height: 33px;
  line-height: 33px;
}
.el-card[data-v-08033a86] {
  border-color: #ffffff;
}
[data-v-08033a86] .avue-affix {
  position: relative !important;
}
.statusIcon[data-v-08033a86] {
  position: absolute;
  right: 25%;
  z-index: 10;
  top: 40px;
}
.statusIcon img[data-v-08033a86] {
  width: 70px;
  height: 70px;
  display: block;
}
.exchangeCard[data-v-08033a86] {
  overflow: auto;
  height: calc(100vh - 226px);
}
[data-v-08033a86] .bjs-powered-by {
  display: none;
}
[data-v-08033a86] .el-tabs__item.is-active {
  color: #409EFF;
}
[data-v-08033a86] .el-tabs__active-bar {
  background-color: #409EFF;
}
[data-v-08033a86] .el-tabs__item:hover {
  color: #409EFF;
}